@font-face {
  font-family: "Moderat";
  src: local("Moderat Regular"),
  url("../../public/fonts/Moderat-Regular-Web/Moderat-Regular.woff") format("woff"),
  url("../../public/fonts/Moderat-Regular-Web/Moderat-Regular.woff2") format("woff2"),
  url("../../public/fonts/Moderat-Regular-Web/Moderat-Regular.eot") format("embedded-opentype");
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "Moderat";
  src: local("Moderat Medium"),
  url("../../public/fonts/Moderat-Medium-Web/Moderat-Medium.woff") format("woff"),
  url("../../public/fonts/Moderat-Medium-Web/Moderat-Medium.woff2") format("woff2"),
  url("../../public/fonts/Moderat-Medium-Web/Moderat-Medium.eot") format("embedded-opentype");
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: "Moderat";
  src: local("Moderat Bold"),
  url("../../public/fonts/Moderat-Bold-Web/Moderat-Bold.woff") format("woff"),
  url("../../public/fonts/Moderat-Bold-Web/Moderat-Bold.woff2") format("woff2"),
  url("../../public/fonts/Moderat-Bold-Web/Moderat-Bold.eot") format("embedded-opentype");
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: "Moderat Extended";
  src: local("Moderat Extended Medium"),
  url("../../public/fonts/Moderat-Extended-Medium-Web/Moderat-Extended-Medium.woff") format("woff"),
  url("../../public/fonts/Moderat-Extended-Medium-Web/Moderat-Extended-Medium.woff2") format("woff2"),
  url("../../public/fonts/Moderat-Extended-Medium-Web/Moderat-Extended-Medium.eot") format("embedded-opentype");
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: "Moderat Extended";
  src: local("Moderat Extended Bold"),
  url("../../public/fonts/Moderat-Extended-Bold-Web/Moderat-Extended-Bold.woff") format("woff"),
  url("../../public/fonts/Moderat-Extended-Bold-Web/Moderat-Extended-Bold.woff2") format("woff2"),
  url("../../public/fonts/Moderat-Extended-Bold-Web/Moderat-Extended-Bold.eot") format("embedded-opentype");
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: "Moderat Extended";
  src: local("Moderat Extended Black"),
  url("../../public/fonts/Moderat-Extended-Black-Web/Moderat-Extended-Black.woff") format("woff"),
  url("../../public/fonts/Moderat-Extended-Black-Web/Moderat-Extended-Black.woff2") format("woff2"),
  url("../../public/fonts/Moderat-Extended-Black-Web/Moderat-Extended-Black.eot") format("embedded-opentype");
  font-weight: 900;
  font-display: swap;
}

/* @font-face {
  font-family: "Moderat Mono";
  src:
      url("../../public/fonts/Moderat-Mono-Medium-Web/Moderat-Mono-Medium.woff") format("woff"),
      url("../../public/fonts/Moderat-Mono-Medium-Web/Moderat-Mono-Medium.woff2") format("woff2"),
      url("../../public/fonts/Moderat-Mono-Medium-Web/Moderat-Mono-Medium.eot") format("embedded-opentype");
  font-weight: 500;
} */
