@import "swiper/css";

@import "~slick-carousel/slick/slick.scss";
@import "~slick-carousel/slick/slick-theme.scss";

html,
body {
  font-family: "Moderat", system-ui, Roboto, Ubuntu, Cantarell, Noto Sans,
    sans-serif;
  @apply text-black-1000 font-normal;
  font-feature-settings: "ss02" on, "calt" off;
  font-size: 16px;
}

body {
  @apply text-2xl;
}

.h1,
h1 {
  @apply text-6xl sm:text-7xl md:text-8xl font-bold;
  @apply mb-8 sm:mb-8 md:mb-8;
}

.h2,
h2 {
  @apply text-5xl sm:text-6xl md:text-7xl font-medium;
  @apply mb-7 sm:mb-7 md:mb-8;
}

.h3,
h3 {
  @apply text-4xl sm:text-5xl md:text-6xl font-medium;
  @apply mb-6 sm:mb-6 md:mb-8;
}

.h4,
h4 {
  @apply text-3xl sm:text-4xl md:text-5xl font-medium;
  @apply mb-5 sm:mb-6 md:mb-8;
}

.h5,
h5 {
  @apply text-2xl sm:text-3xl font-medium;
  @apply mb-4 sm:mb-6 md:mb-6;
}

.h6,
h6 {
  @apply text-lg font-bold uppercase;
  @apply mb-4 sm:mb-6 md:mb-6;
  @apply leading-normal;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Moderat Extended", system-ui, Roboto, Ubuntu, Cantarell,
    Noto Sans, sans-serif;
  @apply text-black-1000;
}

.hero-text {
  @apply text-4xl md:text-7xl 2xl:text-10xl font-medium;
}

.teaser {
  @apply text-xl font-medium;
}

.teaser-mobile {
  @apply text-lg font-medium leading-normal;
}

.quote {
  @apply text-2xl font-medium;
  font-family: "Moderat Extended", system-ui, Roboto, Ubuntu, Cantarell,
    Noto Sans, sans-serif;
}

.caption {
  @apply text-base font-normal;
  line-height: 1.4;
}

.button-regular {
  @apply text-xl font-medium;
  line-height: 1.2;
  letter-spacing: 0.03em;
}

.button-small {
  @apply text-sm font-medium uppercase;
  line-height: 1.2;
  letter-spacing: 0.03em;
}

.swiper-button-prev {
  background: red;
  height: 30px;
}

.swiper-button-next {
  background: blue;
  height: 30px;
}

p {
  @apply mb-6;
}

a {
  -webkit-text-decoration: underline 2px;
  -moz-text-decoration: underline 2px;
  text-decoration: underline;
  text-decoration-skip-ink: none;
  text-underline-offset: 2px;
  &:hover,
  &:focus {
    @apply no-underline;
  }
}

ul,
ol {
  @apply pl-5 mb-6;
  list-style-type: "//";
  li {
    @apply pl-4;
    > p {
      @apply mb-0;
    }

    ul,
    ol {
      @apply mb-0;
    }
  }
}

ol {
  @apply list-decimal pl-6;
}

button {
  -webkit-tap-highlight-color: transparent;
}

.button {
  font-size: 1.25rem;
  line-height: 1.5rem;

  @apply inline-flex items-center gap-2 border rounded-lg no-underline relative z-0 transition-all duration-150 ease-out px-6 py-4 overflow-hidden;

  font-family: "Moderat Extended", system-ui, Roboto, Ubuntu, Cantarell,
    Noto Sans, sans-serif;

  @apply bg-transparent text-black-1000 border-black;

  & svg {
    @apply w-6 h-6;
  }

  &:after {
    @apply absolute inset-0 bg-black h-[250%];
    content: "";
    z-index: -1;
    transform: translate3d(0, 100%, 0);
    transition: all 0.7s;
  }

  &:hover,
  &:active {
    @apply no-underline;
    @apply text-white;

    &:after {
      transform: translate3d(0, 0, 0) skewY(15deg) scale(1, 3);
    }
  }

  &--inverted {
    @apply text-white border-white;

    &:after {
      @apply bg-white;
    }

    &:hover,
    &:active {
      @apply text-black;
    }
  }

  &--cta {
    @apply text-white bg-black border-black;

    &:after {
      @apply bg-white;
    }

    &:hover,
    &:active {
      @apply text-black border-white;
    }
  }

  &--filled {
    @apply bg-white;
  }

  &--inverted,
  &--filled {
    @apply bg-black border-white;
  }

  &--ghost {
    @apply border-none;

    &:after {
      @apply hidden;
    }

    &:hover,
    &:active {
      @apply underline text-black;
    }
  }
}

.main {
  @apply overflow-hidden;
}

.menu-open {
  @apply bg-black;

  .header {
    @apply hidden lg:flex;
  }
  .main,
  .footer {
    @apply hidden lg:block;
  }
}

@screen lg {
  .menu-open {
    @apply bg-white;
  }
}

.grecaptcha-badge {
  visibility: hidden;
}

/* Text highlights */
.highlight-text {
  @apply px-2 py-1 inline-block;

  clip-path: polygon(20px 0, 100% 0, 100% 100%, 0 100%, 0% 20px);

  &[data-color="vollmagenta"] {
    @apply bg-vollmagenta-700;
  }

  &[data-color="vollgreen"] {
    @apply bg-vollgreen-700;
  }

  &[data-color="vollorange"] {
    @apply bg-vollorange-700;
  }

  &[data-color="vollyellow"] {
    @apply bg-vollyellow-700;
  }
}

// Images Zoom
@media (max-width: 499px) {
  [data-rmiz-overlay] {
    overflow: auto !important;
  }

  [data-rmiz-modal-content] {
    width: 150vw !important;
    height: auto !important;
    margin-top: -3vw;
  }

  [data-rmiz-btn-close] {
    width: 160vw !important;
  }
}

// hyphens for small screen devices max 499px
@media (max-width: 499px) {
  body {
    @apply hyphens;
    word-break: break-word;
  }
}
